import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';


import ContentDisplay from '../components/content-display/content-display';
import DefaultLayout from '../components/default-layout/default-layout';
import MegaMenuContext from '../components/mega-menu-context/mega-menu-context';
import SEO from '../components/seo/seo';

const NotFoundPage = ({ data }) => (
  <MegaMenuContext.Provider value={data.MegaMenu && data.MegaMenu.edges}>
    <DefaultLayout data={data}>
      <SEO
        title="Page Not Found"
        description="We couldn't find that page. Try searching or going back to the home page."
        siteConfig={data.site.siteMetadata}
      />
      <ContentDisplay
        title="Not Found"
        body={
          '<div><p>We couldn\'t find that page.</p><p>Try searching or going back to the <a href="/">home page</a>.</p></div>'
        }
      />
    </DefaultLayout>
  </MegaMenuContext.Provider>
);

NotFoundPage.propTypes = {
  data: PropTypes.object
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    MegaMenu: allTaxonomyTermPromotion(
      filter: {
        relationships: {
          parent: {
            elemMatch: {
              drupal_id: {
                eq: "977ebf54-1cc8-4316-9fe6-e599100dc68e"
              }
            }
          }
        }, name: {
          regex: "/^Menu/"
        }
      }
    ) {
      edges {
        node {
          name
          relationships {
            field_promoted_content {
              ...MegaMenuNews
              ...MegaMenuEvent
              ...MegaMenuPlace
            }
          }
        }
      }
    }
  }
`;
